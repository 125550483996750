import Head from 'next/head.js';
import Image from 'next/image';
import Link from 'next/link';
import { SVGProps } from 'react';

import { AppFooter } from '@/components/AppFooter.js';
import { TemplateLanding } from '@/components/TemplateLanding.js';
import { PaintBrushIcon, PlayIcon, SparklesIcon, UsersIcon } from '@heroicons/react/24/solid';

const features = [
  {
    name: 'Instant Collaboration',
    description:
      'Team up with friends or colleagues and create together in real-time. No more waiting, just pure creative fun!',
    Icon: UsersIcon,
  },
  // {
  //   name: 'Stylish Templates',
  //   description:
  //     'Jumpstart your projects with our awesome templates. Perfect for hobbyists and pros alike!',
  //   Icon: PaintBrushIcon,
  // },
  {
    name: 'Easy to Use',
    description:
      'No 3D experience? No problem! Our intuitive interface lets you dive right in and start building.',
    Icon: SparklesIcon,
  },
  {
    name: 'Interactive Scenes',
    description:
      'Bring your creations to life with interactive elements. Make your 3D scenes pop and impress your audience!',
    Icon: PlayIcon,
  },
];

export default function Home() {
  return (
    <TemplateLanding pageName="Home">
      <Head>
        <meta property="og:image" content="/assets/graphics/hero.jpg" />
      </Head>
      <div className="relative isolate overflow-hidden bg-gray-950">
        <video
          autoPlay
          loop
          muted
          className="hue-rotate-continuous inset-0 -z-10 absolute h-full w-full object-cover opacity-30"
        >
          <source src="/assets/graphics/hero_h265.mp4" type="video/mp4; codecs=hev1" />
          <source src="/assets/graphics/hero_h264.mp4" type="video/mp4; codecs=avc1" />
          <Image
            src="/assets/graphics/hero.jpg"
            alt="a 3d world"
            width="1920"
            height="968"
            placeholder="blur"
            blurDataURL="L8Em;~sp00WW4AW-05ay0]oNswjb"
          />
        </video>
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center">
            <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-400 ring-1 ring-white/10 hover:ring-white/20">
              The fun way to create 3D{' '}
              <Link href="/" className="font-semibold text-white">
                <span className="absolute inset-0" aria-hidden="true" />
                Create Now! <span aria-hidden="true">&rarr;</span>
              </Link>
            </div>
          </div>
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
              Create together, explore together
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Dive into the world of 3D creation with ease. Whether you&apos;re prototyping larger
              scenes or crafting an interactive playground, 3dpage makes it fun and simple. Unleash
              your creativity and explore endless possibilities.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Link
                href="/create"
                className="rounded-md bg-brand-dark-pink px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
              >
                Start Building Now
              </Link>
              <Link href="/signup" className="text-sm font-semibold leading-6 text-white">
                Sign up for free <span aria-hidden="true">→</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-hidden bg-white dark:bg-gray-900 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-lg">
                <h2 className="text-base font-semibold leading-7 text-brand-dark-pink">
                  Create with Ease
                </h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-800 dark:text-gray-300 sm:text-4xl">
                  Everything you need for your 3D adventures
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-700 dark:text-gray-400">
                  Ready to explore the fun world of 3D creation? Our platform is designed to make it
                  easy and enjoyable. Jump in and start creating with our awesome tools and
                  features!
                </p>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-800 dark:text-gray-300 lg:max-w-none">
                  {features.map(({ name, description, Icon }) => (
                    <div key={name} className="relative pl-9">
                      <dt className="inline font-semibold text-gray-800 dark:text-gray-300">
                        <Icon
                          className="absolute left-1 top-1 h-5 w-5 text-brand-dark-pink"
                          aria-hidden="true"
                        />
                        {name}
                      </dt>{' '}
                      <dd className="inline">{description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
            <video
              autoPlay
              loop
              muted
              className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-white/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            >
              <source src="/assets/graphics/demo_h265.mp4" type="video/mp4; codecs=hev1" />
              <source src="/assets/graphics/demo_h264.mp4" type="video/mp4; codecs=avc1" />
              <Image
                src="/assets/graphics/demo.jpg"
                alt="3dpage demo"
                width="1920"
                height="1080"
                placeholder="blur"
                blurDataURL="L+CaGNj[jsj[.Tj[jsj[%hj[jZj["
              />
            </video>
          </div>
        </div>
      </div>
      <div className="bg-white dark:bg-gray-950">
        <div className="mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8">
          <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 text-center shadow-2xl sm:rounded-3xl sm:px-16">
            <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Try without signing up
            </h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
              We&apos;re pretty new! We&apos;re not going to bore you with a ton of content on the
              home page. BUT you can click this shiny button right here and just try it out
              yourself.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Link
                href="/create"
                className="flex justify-center items-center relative h-12 overflow-hidden rounded-md bg-brand-dark-pink px-6 text-neutral-50 transition hover:bg-brand-medium-pink text-white"
              >
                <span className="relative">Create a scene now!</span>
                <div className="animate-shine-infinite absolute inset-0 -top-[20px] flex h-[calc(100%+40px)] w-full justify-center blur-[12px]">
                  <div className="relative h-full w-8 bg-white/30"></div>
                </div>
              </Link>
            </div>
            <svg
              viewBox="0 0 1024 1024"
              className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
              aria-hidden="true"
            >
              <circle
                cx={512}
                cy={512}
                r={512}
                fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)"
                fillOpacity="0.7"
              />
              <defs>
                <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                  <stop stopColor="#7775D6" />
                  <stop offset={1} stopColor="#E935C1" />
                </radialGradient>
              </defs>
            </svg>
          </div>
        </div>
      </div>
      <AppFooter />
    </TemplateLanding>
  );
}
